@import "bootstrap/bootstrap.less";
@import url('https://fonts.googleapis.com/css?family=Muli:400,700|Ubuntu:400,700');

html,
body {
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
}

/////////////////////////////////////////////////////////
//
// General scaffolding
//
/////////////////////////////////////////////////////////

img {
	max-width: 100%;
	height: auto;
}

.page__content {
	.make-sm-column(8);
	margin-bottom: 30px;
}

.block {
	.make-sm-column(3);
	margin-bottom: 30px;
}

	.block--third {
		.make-sm-column(4);
	}

	.block--half {
		.make-sm-column(6);
	}
	
	.block--wide {
		.make-sm-column(9);
	}
	
	.block--half-right {
		.make-sm-column-offset(6);
	}
	
/////////////////////////////////////////////////////////
//
// Buttons
//
/////////////////////////////////////////////////////////

.btn {
	text-transform: uppercase;
	letter-spacing: 1px;
	border: transparent !important;
	box-shadow: none;
}
	.btn-primary {
		background: @activus-blue;
		
		&:hover,
		&:focus {
			background: darken(@activus-blue, 10%);
		}
	}
	
	.btn-danger {
		background: @activus-red;
		
		&:hover,
		&:focus {
			background: darken(@activus-red, 10%);
		}
	}
	
	.btn-default {
		background: @activus-gray;
		
		&:hover,
		&:focus {
			background: darken(@activus-gray, 10%);
		}
	}
	
	
.btn-group {
	.btn {
		margin-left: 5px !important;
	}
}
/////////////////////////////////////////////////////////
//
// Header
//
/////////////////////////////////////////////////////////

.navbar {
	margin-bottom: 0;
	padding: 10px 0;
	background: #fff;
}

	.navbar-brand {
		height: auto;
		margin-right: 50px;
	}
		.logo {
			width: 180px;
		}

	.nav {
		margin-top: 24px;
		
		> li {
			> a {
				color: #8e8e8e;
			}
			
			&.active > a,
			> a:hover,
			> a:focus {
				background: @activus-red !important;
				color: white !important;
			}
		}
	}

.icon-bar {
	background: @activus-red;
}

.page-header-bar {
		height: 10px;
		width: 100%;
		background: @activus-gray;
	}
	
		.page-header-bar-left {
			width: 33.33%;
			float: left;
			height: 10px;
			background: @activus-red;
		}
		
		.page-header-bar-right {
			width: 33.33%;
			float: right;
			height: 10px;
			background: @activus-blue;
		}

/////////////////////////////////////////////////////////
//
// Jumbotron
//
/////////////////////////////////////////////////////////

.jumbotron {
	height: 520px;
	width: 100%;
	background-image: url("../img/bg-jumbotron.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	margin-bottom: 0;
	position: relative;
	padding: 0;
}

		.jumbotron--page {
			height: 150px;
		}
		
/////////////////////////////////////////////////////////
//
// Jumbotron
//
/////////////////////////////////////////////////////////

.container--page {
	background: white;
	padding: 30px 30px 30px;
	margin-top: -60px;
	position: relative;
	z-index: 1;
}

/////////////////////////////////////////////////////////
//
// Sections
//
/////////////////////////////////////////////////////////

.section--main {
	background: @activus-gray-light;
	padding-bottom: 50px;
}
	.pageTitle {
		margin-top: 0;
	}

/////////////////////////////////////////////////////////
//
//  Dashboard
//
/////////////////////////////////////////////////////////

.dashboardColumn {
	.make-sm-column(6);
	
	.candidateResultLink {
		&:hover {
			&:after {
				display: none;
			}
		}
		
		@media (max-width: @screen-xs-max) {
			display: block;
		}
	}
}


/////////////////////////////////////////////////////////
//
// Page Sidebar
//
/////////////////////////////////////////////////////////

.alert-info {
	background: @activus-gray-light !important;
	color: @text-color;
}

	.sidebarTitle {
		margin-top: 0;
	}

	.jobalertCheck {
		margin-top: 20px;
		padding: 7px 15px;
		background: @activus-gray-light;
		color: @activus-gray-dark;
		border: 1px solid @activus-gray;
		display: inline-block;
	}

/////////////////////////////////////////////////////////
//
//  Search page
//
/////////////////////////////////////////////////////////

.candidateResults {
	.make-sm-column(5);
	border-right: 1px solid #ccc; 
	border-left: 1px solid #ccc; 
}

	.candidateResult {
		
	}	
		.candidateResultLink {
			.make-sm-column(12);
			padding: 15px 15px 5px 20px;
			color: @text-color;
			text-decoration: none;
			background-color: @activus-gray-light;
			margin-bottom: 15px;
			.transition(all 0.2s ease);
			
			&:hover,
			&:focus,
			&:active {
				background: @activus-red;
				color: #fff;
				text-decoration: none;
				
				&:after {
					content: '';
					width: 0; 
					height: 0; 
					border-top: 10px solid transparent;
					border-bottom: 10px solid transparent;
					border-left: 10px solid @activus-red;
					position: absolute;
					right: -10px;
					top: 50%;
					margin-top: -10px;
				}
			}
			
			
		}
		
			.candidateResultTitle {
				margin: 0 0 5px;
				font-size: 20px;
			}

.candidateInfo {
	.make-sm-column(7);
	padding: 0 40px;
}

.newCandidateInfo,
.candidateInfo {
	ul {
		list-style: square;
	}
	
	a {
		@media (max-width: @screen-xs-max) {
			margin-bottom: 10px;
		}
	}
}

.candidateForm {
	.form-group {
		input {
			width: 100%;
		}
	}
}	

.form-group {
	input {
		width: 100%;
	}
}
	
/////////////////////////////////////////////////////////
//
// Forms
//
/////////////////////////////////////////////////////////
	
.form-control[disabled], 
.form-control[readonly], 
fieldset[disabled] .form-control {
	background: lighten(@gray-base, 97%);
	border: 0;
	box-shadow: none;
	height: auto;
	line-height: 1.2;
}

input[type="date"].form-control {
	line-height: 1.2;
}


.searchformContent {
	border: none;
	background: transparent !important;
}